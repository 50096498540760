// extracted by mini-css-extract-plugin
export var alignLeft = "x_qL d_bG";
export var alignCenter = "x_bP d_bD";
export var alignRight = "x_qM d_bH";
export var textAlignLeft = "x_qN";
export var textAlignCenter = "x_qP";
export var textAlignRight = "x_qQ";
export var hoursInnerWrapperAlt = "x_t6 d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "x_t7 d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "x_t8 d_dw";
export var titleMargin = "x_qT d_cw";
export var hoursInnerInnerWrapper = "x_t9 d_cz";