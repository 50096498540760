// extracted by mini-css-extract-plugin
export var alignLeft = "n_qL d_bG";
export var alignCenter = "n_bP d_bD";
export var alignRight = "n_qM d_bH";
export var textAlignLeft = "n_qN";
export var textAlignCenter = "n_qP";
export var textAlignRight = "n_qQ";
export var embedInnerWrapperDesign1 = "n_qR d_bM";
export var embed = "n_qS d_b1";
export var titleMargin = "n_qT d_cw d_dw";
export var subtitleMargin = "n_qV d_cs d_dw";
export var paragraphMargin = "n_qW d_cw d_dw";
export var SubtitleSmall = "n_qd s_qd s_rN s_rZ";
export var SubtitleNormal = "n_qf s_qf s_rN s_r0";
export var SubtitleLarge = "n_qg s_qg s_rN s_r1";
export var BodySmall = "n_qX s_qX s_rN s_r5";
export var BodyNormal = "n_qY s_qY s_rN s_r6";
export var BodyLarge = "n_qZ s_qZ s_rN s_r7";