// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_rx d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_ry d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_rz d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_rB d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_rC d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_rD d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_rF d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_rG s_rG";
export var heroExceptionNormal = "r_rH s_rH";
export var heroExceptionLarge = "r_rJ s_rJ";
export var Title1Small = "r_rK s_rK s_rN s_rP";
export var Title1Normal = "r_rL s_rL s_rN s_rQ";
export var Title1Large = "r_rM s_rM s_rN s_rR";
export var BodySmall = "r_qX s_qX s_rN s_r5";
export var BodyNormal = "r_qY s_qY s_rN s_r6";
export var BodyLarge = "r_qZ s_qZ s_rN s_r7";