// extracted by mini-css-extract-plugin
export var tileContent = "z_vj d_w d_H d_Z";
export var teamTextLeft = "z_vk d_dv";
export var teamTextCenter = "z_vl d_dw";
export var teamTextRight = "z_vm d_dx";
export var alignLeft = "z_qL d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "z_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "z_qM d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "z_vn d_dW";
export var teamContainerFull = "z_vp d_dT";
export var teamRowWrapper = "z_vq d_cc";
export var teamTileWrapper = "z_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "z_vr d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "z_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "z_j5 d_j5 d_Z";
export var teamHoverNoGutters = "z_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "z_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "z_vs d_j7";
export var teamInfoWrapperRound = "z_j7 d_j7";
export var teamInfoWrapper = "z_vt d_j8 d_0";
export var teamInfoWrapperNoGutters = "z_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "z_jZ d_jZ";
export var teamImgWrapperAlt = "z_j0 d_j0";
export var teamImgWrapperNoGutters = "z_kb d_kb";
export var teamHeader = "z_vv d_cw";
export var teamHeaderAlt = "z_vw d_cw";
export var teamHeaderNoGutters = "z_vx d_cw d_cD";